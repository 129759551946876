import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const Story = props => {
  const data = useStaticQuery(graphql `
    query {
      pages: allContentfulPages(filter: {id: {eq: "71bc1ee6-6bcf-550a-8c1a-77228b5030f7"}}) {
        edges {
          node {
            title
            id
            subTitleOne
            subBodyOne {
              subBodyOne
            }
            subTitleTwo
            subBodyTwo {
              subBodyTwo
            }
            imageOne {
              file {
                url
                fileName
              }
            }
            imageTwo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      {data.pages.edges.map((edge) => {
        return (
          <div>
              <Grid
                container
                justify="space-between"
                spacing={isMd ? 4 : 2}
                direction={isMd ? 'row' : 'column-reverse'}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="flex-start"
                  xs={12}
                  md={6}
                  data-aos={'fade-up'}
                >
                  <div>
                    <SectionHeader
                      title={edge.node.subTitleOne}
                      subtitle={edge.node.subBodyOne.subBodyOne}
                      align="left"
                      disableGutter
                      subtitleProps={{
                        color: 'textPrimary',
                        variant: 'body1',
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  justify={isMd ? 'flex-end' : 'flex-start'}
                  alignItems="center"
                  xs={12}
                  md={6}
                  data-aos={'fade-up'}
                >
                  <Image
                    src={edge.node.imageOne.file.url}
                    alt="Theme"
                    className={classes.image}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justify="space-between"
                spacing={isMd ? 4 : 2}
                direction={isMd ? 'row' : 'column-reverse'}
              >
                <Grid
                  item
                  container
                  justify={isMd ? 'flex-end' : 'flex-start'}
                  alignItems="left"
                  xs={12}
                  md={4}
                  data-aos={'fade-up'}
                >
                  <Image
                    src={edge.node.imageTwo.file.url}
                    alt="Theme"
                    className={classes.image}
                  />
                </Grid>

                <Grid
                  item
                  container
                  alignItems="center"
                  justify="flex-start"
                  xs={12}
                  md={7}
                  data-aos={'fade-up'}
                >
                  <div>
                    <SectionHeader
                      title={edge.node.subTitleTwo}
                      subtitle={edge.node.subBodyTwo.subBodyTwo}
                      align="left"
                      disableGutter
                      subtitleProps={{
                        color: 'textPrimary',
                        variant: 'body1',
                      }}
                    />
                  </div>
                </Grid>
                
              </Grid>
          </div>
        )
      })}
      
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
